<template>
    <div v-if="to === '#'">
         <a href="javascript:void(0)" @click="ShowDropdownMenu" :class="isActive.check && isActive.name === nameLink?'active-sub-link': ''"  class="p-2 link-menu  my-1  flex justify-between items-center">
               <span  class="flex items-center" >
                  <span class="img-link"><img style="height: 25px;" class="mx-2 img-storeino" v-if="img" :src="img" /><img style="height: 25px;" class="mx-2 img-storeino" v-if="imghover" :src="imghover" /></span>
                  <i v-if="icon" class="material-icons mx-2">{{ icon }}</i><span class="text-menu">{{ text }}</span>
               </span>
         </a> 
    </div>
    <div v-else-if="isLinkHref">
         <a :target="target" :href="to" @click.native="HideDropdownMenu"  class="p-2  my-1 link-menu flex justify-between items-center" :class="[submenu?'sub-link': '',children?!nameLink?'sub-sub-link':'sub-sub-link-'+nameLink: '',nameLink?nameLink:'']" >
           <span  class="flex items-center">
               <span class="img-link"><img style="height: 25px;" class="mx-2 img-storeino" v-if="img" :src="img" /><img style="height: 25px;" class="mx-2 img-storeino" v-if="imghover" :src="imghover" /></span>
               <i v-if="icon" class="material-icons mx-2">{{ icon }}</i><span class="text-menu">{{ text }}</span>
           </span>
        </a>
    </div>
    <div v-else>
         <router-link :target="target" :to="to" @click.native="HideDropdownMenu"  class="p-2  my-1 link-menu flex justify-between items-center" :class="[submenu?'sub-link': '',children?!nameLink?'sub-sub-link':'sub-sub-link-'+nameLink: '',nameLink?nameLink:'']" >
           <span  class="flex items-center">
               <span class="img-link"><img style="height: 25px;" class="mx-2 img-storeino" v-if="img" :src="img" /><img style="height: 25px;" class="mx-2 img-storeino" v-if="imghover" :src="imghover" /></span>
               <i v-if="icon" class="material-icons mx-2">{{ icon }}</i><span class="text-menu">{{ text }}</span>
           </span>
        <!--<i v-if="!submenu" class="material-icons no-active">keyboard_arrow_right</i>
            <i v-if="!submenu" class="material-icons active">keyboard_arrow_down</i>-->
        </router-link>
    </div>
   
</template>
<script>
import $ from "jquery";
import { split } from "lodash";
export default {
    props:{
        to: { type: String, default: '#' },
        text: { type: String, default: 'Link' },
        icon: { type: String, default: '' },
        img: { type: String, default: '' },
        imghover: { type: String, default: '' },
        submenu: false,
        children: false,
        target: false,
        isActive: { type: Object,default: () => ({check:false,name:''}) },
        name: { type: String, default: 'name' },
        isLinkHref: { type: String, default: '' },

    },
    data() {
        return {
            nameLink: this.name
        };
    },
    watch: {
    
        'name': function () {
            this.nameLink=this.name;
        },
   
    },
    async mounted() {
          if(this.isActive.check && this.nameLink === this.isActive.name){
              $('.sub-sub-link-'+this.nameLink).attr("data-show-"+this.nameLink, "");
          }
    },
     methods: {
        ShowDropdownMenu() {
            const dropdownMenu = $(".sub-sub-link-"+this.nameLink);

             this.$emit('myFilter',this.nameLink);
             
             console.log('nameLink------',this.nameLink)
            console.log('this.isActive.check------',this.isActive)
            console.log('$route------',this.$route)

             if(this.isActive.check && this.nameLink === this.isActive.name){
                $(dropdownMenu).attr("data-show-"+this.nameLink, "");
               
             } 
             if(!this.isActive.check && this.nameLink === this.isActive.name){
                $(dropdownMenu).removeAttr("data-show-"+this.nameLink);
             }  
             
        },
        HideDropdownMenu(){
              this.$emit('myFilter',this.nameLink);
              const dropdownMenu = $(".sub-sub-link-"+this.nameLink);

              $(dropdownMenu).removeAttr("data-show-"+this.nameLink);
        }
     }
}
</script>
<style scoped> 


.sub-link,.sub-sub-link,.sub-sub-link-shippings,.sub-sub-link-salaires,.sub-sub-link-pod,.sub-sub-link-analytics,.sub-sub-link-affiliates,.sub-sub-link-paymentss,.sub-sub-link-supports {
   /* background-color:dodgerblue;*/
    margin: 0;
    padding-left: 30px;
}
.child .sub-link{
   padding-left: 40px;
}
.active-sub-link .sub-sub-link,.active-sub-link .sub-sub-link-shippings,.active-sub-link .sub-sub-link-salaires,.active-sub-link .sub-sub-link-pod,.active-sub-link .sub-sub-link-analytics,.active-sub-link .sub-sub-link-affiliates,.active-sub-link .sub-sub-link-paymentss,.active-sub-link .sub-sub-link-supports {
   display: block;
}
.sub-sub-link,.sub-sub-link-shippings,.sub-sub-link-salaires,.sub-sub-link-pod,.sub-sub-link-analytics,.sub-sub-link-affiliates,.sub-sub-link-paymentss,.sub-sub-link-supports{
  display: none;
}
.sub-sub-link-salaires[data-show-salaires],.sub-sub-link-pod[data-show-pod],.sub-sub-link-shippings[data-show-shippings],.sub-sub-link-analytics[data-show-analytics],.sub-sub-link-affiliates[data-show-affiliates],.sub-sub-link-paymentss[data-show-paymentss],.sub-sub-link-supports[data-show-supports],.router-link-active.sub-sub-link,.router-link-active.sub-sub-link-shippings,.router-link-active.sub-sub-link-salaires,.router-link-active.sub-sub-link-analytics,.router-link-active.sub-sub-link-affiliates,.router-link-active.sub-sub-link-paymentss,.router-link-active.sub-sub-link-supports {
  display: flex;
}
.router-link-active:not(.no-active) {
    /* font-weight:bold; */
    /* margin: 0; */
}
.material-icons.active{
    display: none;
}
.router-link-exact-active:not(.no-active){
    background-color: white;
    border-radius: 6px;
    color:#01a653;
    margin: 0;
}
.router-link-active:not(.no-active) .material-icons.active{
    display: inline;
}
.router-link-active:not(.no-active) .material-icons.no-active{
    display: none;
}
</style>